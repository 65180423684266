export interface Page {
  elementName: string
  name: string
  path: string
  pathRegExp: RegExp

  secure?: boolean
}

const pages: Page[] = [
  {
    name: 'Acceptable Use Policy',
    elementName: 'AcceptableUsePolicy',
    path: '/acceptable-use-policy',
    pathRegExp: /\/acceptable-use-policy$/
  },
  {
    name: 'Cookie Policy',
    elementName: 'CookiePolicy',
    path: '/cookie-policy',
    pathRegExp: /\/cookie-policy$/
  },
  {
    name: 'Home',
    elementName: 'Home',
    path: '/',
    pathRegExp: /\/$/
  },
  {
    name: 'Oauth',
    elementName: 'Oauth',
    path: '/oauth',
    pathRegExp: /\/oauth$/
  },
  {
    name: 'Settings',
    elementName: 'Settings',
    path: '/settings',
    pathRegExp: /\/settings$/,
    secure: true
  },
  {
    name: 'Subscription',
    elementName: 'Subscription',
    path: '/subscription',
    pathRegExp: /\/subscription$/,
    secure: true
  },
  {
    name: 'Subscription',
    elementName: 'Subscription',
    path: '/subscription/:guildId',
    pathRegExp: /\/subscription\/[\w-]+$/,
    secure: true
  },
  {
    name: 'Subscriptions',
    elementName: 'Subscriptions',
    path: '/subscriptions',
    pathRegExp: /\/subscriptions$/,
    secure: true
  },
  {
    name: 'Checkout',
    elementName: 'Checkout',
    path: '/checkout/:guildId/:plan/:planDuration/:provider?/:step?',
    pathRegExp: /\/checkout\/[\w-]+\/[\w-]+\/[\w-]+(\/[\w-]+)?(\/complete)?$/,
    secure: true
  },
  {
    name: 'Privacy Policy',
    elementName: 'PrivacyPolicy',
    path: '/privacy-policy',
    pathRegExp: /\/privacy-policy$/
  },
  {
    name: 'Refund Policy',
    elementName: 'RefundPolicy',
    path: '/refund-policy',
    pathRegExp: /\/refund-policy$/
  },
  {
    name: 'Security Policy',
    elementName: 'SecurityPolicy',
    path: '/security-policy',
    pathRegExp: /\/security-policy$/
  },
  {
    name: 'Terms and Conditions',
    elementName: 'TermsAndConditions',
    path: '/terms-and-conditions',
    pathRegExp: /\/terms-and-conditions$/
  },
  {
    name: 'Dashboard',
    elementName: 'Dashboard',
    path: '/dashboard',
    pathRegExp: /\/dashboard$/,
    secure: true
  },
  {
    name: 'Guild Detail',
    elementName: 'GuildDetail',
    path: '/dashboard/:guildId',
    pathRegExp: /\/dashboard\/\d+$/,
    secure: true
  },
  {
    name: 'Leaving Message',
    elementName: 'LeavingMessage',
    path: '/dashboard/:guildId/leaving-message',
    pathRegExp: /\/dashboard\/[\w-]+\/leaving-message$/,
    secure: true
  },
  {
    name: 'Notifications',
    elementName: 'Notifications',
    path: '/dashboard/:guildId/notifications',
    pathRegExp: /\/dashboard\/[\w-]+\/notifications$/,
    secure: true
  },
  {
    name: 'Add Notification',
    elementName: 'AddNotification',
    path: '/dashboard/:guildId/notifications/add',
    pathRegExp: /\/dashboard\/[\w-]+\/notifications\/add$/,
    secure: true
  },
  {
    name: 'Edit Notification',
    elementName: 'EditNotification',
    path: '/dashboard/:guildId/notifications/:notificationId/edit',
    pathRegExp: /\/dashboard\/[\w-]+\/notifications\/[\w-]+\/edit$/,
    secure: true
  },
  {
    name: 'View Notification',
    elementName: 'NotificationDetail',
    path: '/dashboard/:guildId/notifications/:notificationId',
    pathRegExp: /\/dashboard\/[\w-]+\/notifications\/[\w-]+?$/,
    secure: true
  },
  {
    name: 'Personalized Bot',
    elementName: 'PersonalizedBot',
    path: '/dashboard/:guildId/personalized-bot',
    pathRegExp: /\/dashboard\/[\w-]+\/personalized-bot$/,
    secure: true
  },
  {
    name: 'Polls',
    elementName: 'Polls',
    path: '/dashboard/:guildId/polls',
    pathRegExp: /\/dashboard\/[\w-]+\/polls$/,
    secure: true
  },
  {
    name: 'Add Poll',
    elementName: 'AddPoll',
    path: '/dashboard/:guildId/:channelId/polls/add',
    pathRegExp: /\/dashboard\/[\w-]+\/[\w-]+\/polls\/add$/,
    secure: true
  },
  {
    name: 'View Poll',
    elementName: 'PollDetail',
    path: '/dashboard/:guildId/polls/:pollId',
    pathRegExp: /\/dashboard\/[\w-]+\/polls\/[\w-]+?$/,
    secure: true
  },
  {
    name: 'View Poll',
    elementName: 'PollDetail',
    path: '/dashboard/:guildId/polls/:pollId/:viewMode',
    pathRegExp: /\/dashboard\/[\w-]+\/polls\/[\w-]+\/(pie|bar)?$/,
    secure: true
  },
  {
    name: 'Permissions',
    elementName: 'Permissions',
    path: '/dashboard/:guildId/permissions',
    pathRegExp: /\/dashboard\/[\w-]+\/permissions$/,
    secure: true
  },
  {
    name: 'Permissions',
    elementName: 'Permissions',
    path: '/dashboard/:guildId/permissions/:viewMode',
    pathRegExp: /\/dashboard\/[\w-]+\/permissions(\/[\w-]+)?$/,
    secure: true
  },
  {
    name: 'Reaction Roles',
    elementName: 'ReactionRoles',
    path: '/dashboard/:guildId/reaction-roles',
    pathRegExp: /\/dashboard\/[\w-]+\/reaction-roles$/,
    secure: true
  },
  {
    name: 'Add Reaction Roles',
    elementName: 'AddReactionRole',
    path: '/dashboard/:guildId/reaction-roles/add',
    pathRegExp: /\/dashboard\/[\w-]+\/reaction-roles\/add$/,
    secure: true
  },
  {
    name: 'Edit Reaction Roles',
    elementName: 'EditReactionRole',
    path: '/dashboard/:guildId/reaction-roles/:reactionRoleId/edit',
    pathRegExp: /\/dashboard\/[\w-]+\/reaction-roles\/[\w-]+\/edit$/,
    secure: true
  },
  {
    name: 'View Reaction Role',
    elementName: 'ReactionRoleDetail',
    path: '/dashboard/:guildId/reaction-roles/:reactionRoleId',
    pathRegExp: /\/dashboard\/[\w-]+\/reaction-roles\/[\w-]+?$/,
    secure: true
  },
  {
    name: 'Quote of the Day',
    elementName: 'QuoteOfTheDay',
    path: '/dashboard/:guildId/quote-of-the-day',
    pathRegExp: /\/dashboard\/[\w-]+\/quote-of-the-day$/,
    secure: true
  },
  {
    name: 'Tiktok Integration',
    elementName: 'TiktokIntegration',
    path: '/dashboard/:guildId/tiktok-integration',
    pathRegExp: /\/dashboard\/[\w-]+\/tiktok-integration$/,
    secure: true
  },
  {
    name: 'Twitch Integration',
    elementName: 'TwitchIntegration',
    path: '/dashboard/:guildId/twitch-integration',
    pathRegExp: /\/dashboard\/[\w-]+\/twitch-integration$/,
    secure: true
  },
  {
    name: 'Welcome Message',
    elementName: 'WelcomeMessage',
    path: '/dashboard/:guildId/welcome-message',
    pathRegExp: /\/dashboard\/[\w-]+\/welcome-message$/,
    secure: true
  },
  {
    name: 'Youtube Integration',
    elementName: 'YoutubeIntegration',
    path: '/dashboard/:guildId/youtube-integration',
    pathRegExp: /\/dashboard\/[\w-]+\/youtube-integration$/,
    secure: true
  }
]

export default pages
