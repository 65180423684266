import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { DailyQuote, DailyQuoteData, DailyQuotePatch } from '../../../models/DailyQuote'

import { Api } from '../../../lib/api'
import { mergeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

import { type DataState, handleFulfilledMergeState, handleFulfilledOverwriteState, handlePending, handleRejected } from '../helpers'

const initialState: DataState<DailyQuote> = {
  creating: false,
  data: [],
  loading: false,
  patching: false,
  removing: false
}

type ICreateParams = {
  data: DailyQuoteData
  params?: Params
}

interface IPatchParams {
  id: string
  data: DailyQuotePatch & { guildId?: string }
  params?: Params
}

export const findAsync = createAsyncThunk('daily-quotes/find', async (params: Params | undefined, { dispatch }): Promise<DailyQuote[]> => {
  try {
    const { data } = await Api.find('daily-quotes', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting Quote of the day data: ${(e as Error).message}` }))
    throw e
  }
})

export const createAsync = createAsyncThunk('daily-quotes/create', async ({ data, params }: ICreateParams, { dispatch }): Promise<DailyQuote> => {
  try {
    const result = await Api.create('daily-quotes', data, params)
    dispatch(createToast({ type: 'success', message: 'Quote of the day updated successfully' }))
    return result
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error creating Quote of the day: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('daily-quotes/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<DailyQuote> => {
  try {
    const result = await Api.patch('daily-quotes', id, data, params)
    dispatch(createToast({ type: 'success', message: 'Quote of the day updated successfully' }))
    return result
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error updating Quote of the day: ${(e as Error).message}` }))
    throw e
  }
})

export const dailyQuotesSlice = createSlice({
  name: 'daily-quotes',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<DailyQuote | DailyQuote[]>) => {
      state.data = mergeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending('loading'))
      .addCase(findAsync.fulfilled, handleFulfilledOverwriteState)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(createAsync.pending, handlePending('creating'))
      .addCase(createAsync.fulfilled, handleFulfilledMergeState)
      .addCase(createAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, handlePending('patching'))
      .addCase(patchAsync.fulfilled, handleFulfilledMergeState)
      .addCase(patchAsync.rejected, handleRejected)
  }
})

export const { dataAdded } = dailyQuotesSlice.actions

export default dailyQuotesSlice.reducer
