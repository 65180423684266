import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { Poll, PollData, PollPatch } from '../../../models/Poll'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

import { type DataState, handleFulfilledMergeState, handleFulfilledOverwriteState, handlePending, handleRejected, handleRemoveFulfilled } from '../helpers'

const initialState: DataState<Poll> = {
  creating: false,
  data: [],
  loading: false,
  patching: false,
  removing: false
}

type ICreateParams = {
  data: PollData
  params?: Params
}

type IPatchParams = {
  id: string
  data: PollPatch
  params?: Params
}

type IRemoveParams = {
  id: string
  params?: Params
}

export const findAsync = createAsyncThunk('polls/find', async (params: Params, { dispatch }): Promise<Poll[]> => {
  try {
    const { data } = await Api.find('polls', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting polls: ${(e as Error).message}` }))
    throw e
  }
})

export const createAsync = createAsyncThunk('polls/create', async ({ data, params }: ICreateParams, { dispatch }): Promise<Poll> => {
  try {
    return await Api.create('polls', data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error creating poll: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('polls/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<Poll> => {
  try {
    return await Api.patch('polls', id, data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error updating poll: ${(e as Error).message}` }))
    throw e
  }
})

export const removeAsync = createAsyncThunk('polls/remove', async ({ id, params }: IRemoveParams, { dispatch }): Promise<Poll> => {
  try {
    const result = await Api.remove('polls', id)
    dispatch(createToast({ type: 'success', message: 'Poll removed successfully' }))
    return result
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error deleting poll: ${(e as Error).message}` }))
    throw e
  }
})

const pollsSlice = createSlice({
  name: 'polls',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<Poll | Poll[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<Poll | Poll[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending('loading'))
      .addCase(findAsync.fulfilled, handleFulfilledOverwriteState)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(createAsync.pending, handlePending('creating'))
      .addCase(createAsync.fulfilled, handleFulfilledMergeState)
      .addCase(createAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, handlePending('patching'))
      .addCase(patchAsync.fulfilled, handleFulfilledMergeState)
      .addCase(patchAsync.rejected, handleRejected)
      .addCase(removeAsync.pending, handlePending('removing'))
      .addCase(removeAsync.fulfilled, handleRemoveFulfilled)
      .addCase(removeAsync.rejected, handleRejected)
  }
})

export const { dataAdded, dataRemoved } = pollsSlice.actions

export default pollsSlice.reducer
