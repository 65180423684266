import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { PollVote } from '../../../models/PollVote'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

import { type DataState, handleFulfilledOverwriteState, handlePending, handleRejected } from '../helpers'

const initialState: DataState<PollVote> = {
  creating: false,
  data: [],
  loading: false,
  patching: false,
  removing: false
}

export const findAsync = createAsyncThunk('poll-votes/find', async (params: Params, { dispatch }): Promise<PollVote[]> => {
  try {
    const { data } = await Api.find('poll-votes', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting poll votes: ${(e as Error).message}` }))
    throw e
  }
})

const pollVotesSlice = createSlice({
  name: 'poll-votes',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<PollVote | PollVote[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<PollVote | PollVote[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending('loading'))
      .addCase(findAsync.fulfilled, handleFulfilledOverwriteState)
      .addCase(findAsync.rejected, handleRejected)
  }
})

export const { dataAdded, dataRemoved } = pollVotesSlice.actions

export default pollVotesSlice.reducer
