import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { PollOption } from '../../../models/PollOption'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'
import { createToast } from '../toasts/toastsSlice'

import { type DataState, handleFulfilledOverwriteState, handlePending, handleRejected } from '../helpers'

const initialState: DataState<PollOption> = {
  creating: false,
  data: [],
  loading: false,
  patching: false,
  removing: false
}

export const findAsync = createAsyncThunk('poll-options/find', async (params: Params, { dispatch }): Promise<PollOption[]> => {
  try {
    const { data } = await Api.find('poll-options', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting poll options: ${(e as Error).message}` }))
    throw e
  }
})

const pollOptionsSlice = createSlice({
  name: 'poll-options',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<PollOption | PollOption[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<PollOption | PollOption[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending('loading'))
      .addCase(findAsync.fulfilled, handleFulfilledOverwriteState)
      .addCase(findAsync.rejected, handleRejected)
  }
})

export const { dataAdded, dataRemoved } = pollOptionsSlice.actions

export default pollOptionsSlice.reducer
