import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { ReactionRole, ReactionRoleData, ReactionRolePatch } from '../../../models/ReactionRole'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

import { type DataState, handleFulfilledMergeState, handleFulfilledOverwriteState, handlePending, handleRejected, handleRemoveFulfilled } from '../helpers'

const initialState: DataState<ReactionRole> = {
  creating: false,
  data: [],
  loading: false,
  patching: false,
  removing: false
}

type ICreateParams = {
  data: ReactionRoleData
  params?: Params
}

type IPatchParams = {
  id: string
  data: ReactionRolePatch
  params?: Params
}

type IRemoveParams = {
  id: string
  params?: Params
}

export const findAsync = createAsyncThunk('reaction-roles/find', async (params: Params, { dispatch }): Promise<ReactionRole[]> => {
  try {
    const { data } = await Api.find('reaction-roles', params)
    return data
  } catch (e: unknown) {
    dispatch(
      createToast({
        type: 'error',
        message: `Error getting reaction-roles: ${(e as Error).message}`
      })
    )
    throw e
  }
})

export const createAsync = createAsyncThunk('reaction-roles/create', async ({ data, params }: ICreateParams, { dispatch }): Promise<ReactionRole> => {
  try {
    const result = await Api.create('reaction-roles', data, params)
    dispatch(createToast({ type: 'success', message: 'Reaction Role created successfully' }))
    return result
  } catch (e: unknown) {
    dispatch(
      createToast({
        type: 'error',
        message: `Error creating reaction role: ${(e as Error).message}`
      })
    )
    throw e
  }
})

export const patchAsync = createAsyncThunk('reaction-roles/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<ReactionRole> => {
  try {
    const result = await Api.patch('reaction-roles', id, data, params)
    dispatch(createToast({ type: 'success', message: 'Reaction Role updated successfully' }))
    return result
  } catch (e: unknown) {
    dispatch(
      createToast({
        type: 'error',
        message: `Error updating reaction role: ${(e as Error).message}`
      })
    )
    throw e
  }
})

export const removeAsync = createAsyncThunk('reaction-roles/remove', async ({ id, params }: IRemoveParams, { dispatch }): Promise<ReactionRole> => {
  try {
    const result = await Api.remove('reaction-roles', id, params)
    dispatch(createToast({ type: 'success', message: 'ReactionRole removed successfully' }))
    return result
  } catch (e: unknown) {
    dispatch(
      createToast({
        type: 'error',
        message: `Error deleting reaction role: ${(e as Error).message}`
      })
    )
    throw e
  }
})

const reactionRolesSlice = createSlice({
  name: 'reaction-roles',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<ReactionRole | ReactionRole[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<ReactionRole | ReactionRole[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending('loading'))
      .addCase(findAsync.fulfilled, handleFulfilledOverwriteState)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(createAsync.pending, handlePending('creating'))
      .addCase(createAsync.fulfilled, handleFulfilledMergeState)
      .addCase(createAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, handlePending('patching'))
      .addCase(patchAsync.fulfilled, handleFulfilledMergeState)
      .addCase(patchAsync.rejected, handleRejected)
      .addCase(removeAsync.pending, handlePending('removing'))
      .addCase(removeAsync.fulfilled, handleRemoveFulfilled)
      .addCase(removeAsync.rejected, handleRejected)
  }
})

export const { dataAdded, dataRemoved } = reactionRolesSlice.actions

export default reactionRolesSlice.reducer
