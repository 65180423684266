import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { Bot, BotPatch } from '../../../models/Bot'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

import { type DataState, handleFulfilledMergeState, handleFulfilledOverwriteState, handlePending, handleRejected } from '../helpers'

interface BotDataState extends DataState<Bot> {
  authorizing: boolean
}

const initialState: BotDataState = {
  authorizing: false,
  creating: false,
  data: [],
  loading: false,
  patching: false,
  removing: false
}

interface IPatchParams {
  id: string
  data: BotPatch
  params?: Params
}

export const findAsync = createAsyncThunk('bots/find', async (params: Params | undefined, { dispatch }): Promise<Bot[]> => {
  try {
    const { data } = await Api.find('bots', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting bots: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('bots/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<Bot> => {
  try {
    return await Api.patch('bots', id, data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error updating bot: ${(e as Error).message}` }))
    throw e
  }
})

export const botsSlice = createSlice({
  name: 'bots',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<Bot | Bot[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<Bot | Bot[]>) => {
      state.data = removeData(state.data, action.payload)
    },
    setAuthorizing: (state, action: PayloadAction<boolean>) => {
      state.authorizing = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending('loading', true))
      .addCase(findAsync.fulfilled, handleFulfilledOverwriteState)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, handlePending('patching'))
      .addCase(patchAsync.fulfilled, handleFulfilledMergeState)
      .addCase(patchAsync.rejected, handleRejected)
  }
})

export const { dataAdded, dataRemoved, setAuthorizing } = botsSlice.actions

export default botsSlice.reducer
