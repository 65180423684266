import { createSelector } from 'reselect'
import type { Bot } from '../models/Bot'
import type { DisabledFeature } from '../models/DisabledFeature'
import type { Guild } from '../models/Guild'
import type { Notification } from '../models/Notification'
import type { Poll } from '../models/Poll'
import type { ReactionRole } from '../models/ReactionRole'
import type { Subscription } from '../models/Subscription'

import type { RootState } from './store'

const compareByGuildId = (guildId: string) => (item: { guildId: string }) => item.guildId === guildId

// bots
export const selectBotAuthorizing = (state: RootState) => state.bots.authorizing
export const selectBotDataByGuildId = (guildId: string, state: RootState) => state.bots.data.find(compareByGuildId(guildId))
export const selectBotsData = (state: RootState) => state.bots.data
export const selectBotsLoading = (state: RootState) => state.bots.loading
export const selectBotsModifying = (state: RootState) => state.bots.creating || state.bots.patching || state.bots.removing

// connection
export const selectConnected = (state: RootState) => state.connection.connected

// daily-quotes
export const selectDailyQuoteDataForGuild = (guildId: string, state: RootState) => state['daily-quotes'].data.find(compareByGuildId(guildId))
export const selectDailyQuoteLoading = (state: RootState) => state['daily-quotes'].loading
export const selectDailyQuoteModifying = (state: RootState) =>
  state['daily-quotes'].creating || state['daily-quotes'].patching || state['daily-quotes'].removing

// disabled-features
export const selectDisabledFeatureDataForGuild = (feature: string, guildId: string, state: RootState) =>
  state['disabled-features'].data.find((disabledFeature: DisabledFeature) => disabledFeature.feature === feature && disabledFeature.guildId === guildId)
export const selectDisabledFeaturesDataForGuild = (guildId: string, state: RootState) => state['disabled-features'].data.find(compareByGuildId(guildId))
export const selectDisabledFeaturesLoading = (state: RootState) => state['disabled-features'].loading

// guilds
export const selectGuildData = (guildId: string, state: RootState) => selectGuildsData(state).find((guild: Guild) => guild.id === guildId)
export const selectGuildsData = createSelector(
  [(state: RootState) => state.guilds.data, (state: RootState) => state.bots.data, (state: RootState) => state.subscriptions.data],
  (guilds: Guild[], bots: Bot[], subscriptions: Subscription[]) => {
    return guilds
      .map((guild: Guild) => {
        const bot = bots.find(compareByGuildId(guild.id))
        const subscription = subscriptions.find(compareByGuildId(guild.id))

        return {
          ...guild,
          hasBot: !!bot?.active,
          subscription
        }
      })
      .sort((a, b) => Number(b.hasBot) - Number(a.hasBot))
  }
)
export const selectGuildsGetting = (guildId: string, state: RootState) => state.guilds.getting[guildId]
export const selectGuildsLoading = (state: RootState) => state.bots.loading || state.guilds.loading

// leaving-messages
export const selectLeavingMessageDataForGuild = (guildId: string, state: RootState) => state['leaving-messages'].data.find(compareByGuildId(guildId))
export const selectLeavingMessagesLoading = (state: RootState) => state['leaving-messages'].loading
export const selectLeavingMessagesModifying = (state: RootState) =>
  state['leaving-messages'].creating || state['leaving-messages'].patching || state['leaving-messages'].removing

// modal
export const selectShowModal = (modalName: string, state: RootState) => state.modal[modalName]

// notifications
export const selectNotificationData = (notificationId: string, state: RootState) =>
  state.notifications.data.find((notification: Notification): boolean => {
    return String(notification.id) === notificationId
  })
export const selectNotificationsDataForGuild = (guildId: string, state: RootState) => state.notifications.data.filter(compareByGuildId(guildId))
export const selectNotificationsLoading = (state: RootState) => state.notifications.loading
export const selectNotificationsModifying = (state: RootState) => state.notifications.creating || state.notifications.patching || state.notifications.removing

// permissions
export const selectPermissionsData = (state: RootState) => state.permissions.data
export const selectPermissionsDataForGuild = (guildId: string, state: RootState) => {
  return state.permissions.data.filter(compareByGuildId(guildId))
}
export const selectPermissionsLoading = (state: RootState) => state.permissions.loading
export const selectPermissionsModifying = (state: RootState) => state.permissions.creating || state.permissions.patching || state.permissions.removing

// polls
export const selectPollData = (pollId: string, state: RootState) =>
  state.polls.data.find((poll: Poll): boolean => {
    return String(poll.id) === pollId
  })
export const selectPollsData = (state: RootState) => state.polls.data
export const selectPollsDataForGuild = (guildId: string, state: RootState) => state.polls.data.filter(compareByGuildId(guildId))
export const selectPollsLoading = (state: RootState) => state.polls.loading
export const selectPollsModifying = (state: RootState) => state.polls.creating || state.polls.patching || state.polls.removing

// poll-options
export const selectPollOptionsDataForPoll = (pollId: string, state: RootState) =>
  state['poll-options'].data.filter((pollOption) => pollOption.pollId === pollId)
export const selectPollOptionsLoading = (state: RootState) => state['poll-options'].loading
export const selectPollsOptionsModifying = (state: RootState) =>
  state['poll-options'].creating || state['poll-options'].patching || state['poll-options'].removing

// poll-votes
export const selectPollVotesDataForPoll = (pollId: string, state: RootState) => state['poll-votes'].data.filter((pollVote) => pollVote.pollId === pollId)
export const selectPollVotesLoading = (state: RootState) => state['poll-votes'].loading
export const selectPollsVotesModifying = (state: RootState) => state['poll-votes'].creating || state['poll-votes'].patching || state['poll-votes'].removing

// reaction-roles
export const selectReactionRoleData = (reactionRoleId: string, state: RootState) =>
  state['reaction-roles'].data.find((reactionRole: ReactionRole): boolean => {
    return String(reactionRole.id) === reactionRoleId
  })
export const selectReactionRolesDataForGuild = (guildId: string, state: RootState) => state['reaction-roles'].data.filter(compareByGuildId(guildId))
export const selectReactionRolesLoading = (state: RootState) => state['reaction-roles'].loading
export const selectReactionRolesModifying = (state: RootState) =>
  state['reaction-roles'].creating || state['reaction-roles'].patching || state['reaction-roles'].removing

// reaction-role-options
export const selectReactionRoleOptionsLoading = (state: RootState) => state['reaction-role-options'].loading
export const selectReactionRoleOptionsDataForReactionRole = (reactionRoleId: string, state: RootState) =>
  state['reaction-role-options'].data.filter((reactionRoleOption) => reactionRoleOption.reactionRoleId === reactionRoleId)
export const selectReactionRoleOptionsModifying = (state: RootState) =>
  state['reaction-role-options'].creating || state['reaction-role-options'].patching || state['reaction-role-options'].removing

// subscriptions
export const selectSubscriptionsDataForGuild = (guildId: string, state: RootState) => state.subscriptions.data.find(compareByGuildId(guildId))
export const selectSubscriptionsDataForCurrentUser = (state: RootState) =>
  state.subscriptions.data.filter((subscription) => subscription.userId === state.users.user?.id)
export const selectSubscriptionsErrorMessage = (state: RootState) => state.subscriptions.errorMessage
export const selectSubscriptionsLoading = (state: RootState) => state.subscriptions.loading
export const selectSubscriptionModifying = (state: RootState) => state.subscriptions.creating || state.subscriptions.patching || state.subscriptions.removing

// theme
export const selectCurrentTheme = (state: RootState) => state.theme.currentTheme
export const selectCurrentThemeType = (state: RootState) => state.theme.currentThemeType

// tiktok-integrations
export const selectTiktokIntegrationsData = (state: RootState) => state['tiktok-integrations'].data
export const selectTiktokIntegrationsLoading = (state: RootState) => state['tiktok-integrations'].loading

// toasts
export const selectToasts = (state: RootState) => state.toasts.data

// twitch-integrations
export const selectTwitchIntegrationsData = (state: RootState) => state['twitch-integrations'].data
export const selectTwitchIntegrationsDataForGuild = (guildId: string, state: RootState) => state['twitch-integrations'].data.find(compareByGuildId(guildId))
export const selectTwitchIntegrationsLoading = (state: RootState) => state['twitch-integrations'].loading
export const selectTwitchIntegrationsModifying = (state: RootState) =>
  state['twitch-integrations'].creating || state['twitch-integrations'].patching || state['twitch-integrations'].removing

// users
export const selectCurrentUser = (state: RootState) => state.users.user
export const selectUsersErrorMessage = (state: RootState) => state.users.errorMessage
export const selectUsersLoading = (state: RootState) => state.users.loading
export const selectUsersPatching = (state: RootState) => state.users.patching

// welcome-messages
export const selectWelcomeMessageDataForGuild = (guildId: string, state: RootState) => state['welcome-messages'].data.find(compareByGuildId(guildId))
export const selectWelcomeMessagesLoading = (state: RootState) => state['welcome-messages'].loading
export const selectWelcomeMessagesModifying = (state: RootState) =>
  state['welcome-messages'].creating || state['welcome-messages'].patching || state['welcome-messages'].removing

// youtube-integrations
export const selectYoutubeIntegrationsData = (state: RootState) => state['youtube-integrations'].data
export const selectYoutubeIntegrationsLoading = (state: RootState) => state['youtube-integrations'].loading
