import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { ReactionRoleOption, ReactionRoleOptionData, ReactionRoleOptionPatch } from '../../../models/ReactionRoleOption'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

import { type DataState, handleFulfilledMergeState, handleFulfilledOverwriteState, handlePending, handleRejected, handleRemoveFulfilled } from '../helpers'

const initialState: DataState<ReactionRoleOption> = {
  creating: false,
  data: [],
  loading: false,
  patching: false,
  removing: false
}

type ICreateParams = {
  data: ReactionRoleOptionData
  params?: Params
}

type IPatchParams = {
  id: string
  data: ReactionRoleOptionPatch
  params?: Params
}

export const findAsync = createAsyncThunk('reaction-role-options/find', async (params: Params, { dispatch }): Promise<ReactionRoleOption[]> => {
  try {
    const { data } = await Api.find('reaction-role-options', params)
    return data
  } catch (e: unknown) {
    dispatch(
      createToast({
        type: 'error',
        message: `Error getting reaction-role-options: ${(e as Error).message}`
      })
    )
    throw e
  }
})

export const createAsync = createAsyncThunk(
  'reaction-role-options/create',
  async ({ data, params }: ICreateParams, { dispatch }): Promise<ReactionRoleOption> => {
    try {
      return await Api.create('reaction-role-options', data, params)
    } catch (e: unknown) {
      dispatch(
        createToast({
          type: 'error',
          message: `Error creating reaction role option: ${(e as Error).message}`
        })
      )
      throw e
    }
  }
)

export const patchAsync = createAsyncThunk(
  'reaction-role-options/patch',
  async ({ id, data, params }: IPatchParams, { dispatch }): Promise<ReactionRoleOption> => {
    try {
      return await Api.patch('reaction-role-options', id, data, params)
    } catch (e: unknown) {
      dispatch(
        createToast({
          type: 'error',
          message: `Error updating reaction role option: ${(e as Error).message}`
        })
      )
      throw e
    }
  }
)

export const removeAsync = createAsyncThunk('reaction-role-options/remove', async (id: string, { dispatch }): Promise<ReactionRoleOption> => {
  try {
    const result = await Api.remove('reaction-role-options', id)
    dispatch(createToast({ type: 'success', message: 'ReactionRoleOption removed successfully' }))
    return result
  } catch (e: unknown) {
    dispatch(
      createToast({
        type: 'error',
        message: `Error deleting reaction role option: ${(e as Error).message}`
      })
    )
    throw e
  }
})

const reactionRoleOptionsSlice = createSlice({
  name: 'reaction-role-options',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<ReactionRoleOption | ReactionRoleOption[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<ReactionRoleOption | ReactionRoleOption[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending('loading'))
      .addCase(findAsync.fulfilled, handleFulfilledOverwriteState)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(createAsync.pending, handlePending('creating'))
      .addCase(createAsync.fulfilled, handleFulfilledMergeState)
      .addCase(createAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, handlePending('patching'))
      .addCase(patchAsync.fulfilled, handleFulfilledMergeState)
      .addCase(patchAsync.rejected, handleRejected)
      .addCase(removeAsync.pending, handlePending('removing'))
      .addCase(removeAsync.fulfilled, handleRemoveFulfilled)
      .addCase(removeAsync.rejected, handleRejected)
  }
})

export const { dataAdded, dataRemoved } = reactionRoleOptionsSlice.actions

export default reactionRoleOptionsSlice.reducer
