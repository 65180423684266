import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { Permission, PermissionPatch } from '../../../models/Permission'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

import { type DataState, handleFulfilledMergeState, handleFulfilledOverwriteState, handlePending, handleRejected } from '../helpers'

const initialState: DataState<Permission> = {
  creating: false,
  data: [],
  loading: false,
  patching: false,
  removing: false
}

interface IPatchParams {
  id: string
  data: PermissionPatch
  params?: Params
}

export const findAsync = createAsyncThunk('permissions/find', async (params: Params | undefined, { dispatch }): Promise<Permission[]> => {
  try {
    const { data } = await Api.find('permissions', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting permissions: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('permissions/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<Permission> => {
  try {
    return await Api.patch('permissions', id, data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: (e as Error).message }))
    throw e
  }
})

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<Permission | Permission[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<Permission | Permission[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending('loading'))
      .addCase(findAsync.fulfilled, handleFulfilledOverwriteState)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, handlePending('patching'))
      .addCase(patchAsync.fulfilled, handleFulfilledMergeState)
      .addCase(patchAsync.rejected, handleRejected)
  }
})

export const { dataAdded, dataRemoved } = permissionsSlice.actions

export default permissionsSlice.reducer
