import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { WelcomeMessage, WelcomeMessagePatch } from '../../../models/WelcomeMessage'

import { Api } from '../../../lib/api'
import { mergeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

import { type DataState, handleFulfilledMergeState, handleFulfilledOverwriteState, handlePending, handleRejected } from '../helpers'

const initialState: DataState<WelcomeMessage> = {
  creating: false,
  data: [],
  loading: false,
  patching: false,
  removing: false
}

interface IPatchParams {
  id: string
  data: WelcomeMessagePatch & { guildId?: string }
  params?: Params
}

export const findAsync = createAsyncThunk('welcomeMessages/find', async (params: Params | undefined, { dispatch }): Promise<WelcomeMessage[]> => {
  try {
    const { data } = await Api.find('welcome-messages', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting welcomeMessages: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('welcomeMessages/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<WelcomeMessage> => {
  try {
    const result = await Api.patch('welcome-messages', id, data, params)
    dispatch(createToast({ type: 'success', message: 'Welcome message updated successfully' }))
    return result
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: (e as Error).message }))
    throw e
  }
})

export const welcomeMessagesSlice = createSlice({
  name: 'welcomeMessages',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<WelcomeMessage | WelcomeMessage[]>) => {
      state.data = mergeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending('loading'))
      .addCase(findAsync.fulfilled, handleFulfilledOverwriteState)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, handlePending('patching'))
      .addCase(patchAsync.fulfilled, handleFulfilledMergeState)
      .addCase(patchAsync.rejected, handleRejected)
  }
})

export const { dataAdded } = welcomeMessagesSlice.actions

export default welcomeMessagesSlice.reducer
