import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { LeavingMessage, LeavingMessagePatch } from '../../../models/LeavingMessage'

import { Api } from '../../../lib/api'
import { mergeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

import { type DataState, handleFulfilledMergeState, handleFulfilledOverwriteState, handlePending, handleRejected } from '../helpers'

const initialState: DataState<LeavingMessage> = {
  creating: false,
  data: [],
  loading: false,
  patching: false,
  removing: false
}

interface IPatchParams {
  id: string
  data: LeavingMessagePatch & { guildId?: string }
  params?: Params
}

export const findAsync = createAsyncThunk('leavingMessages/find', async (params: Params | undefined, { dispatch }): Promise<LeavingMessage[]> => {
  try {
    const { data } = await Api.find('leaving-messages', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting leavingMessages: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('leavingMessages/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<LeavingMessage> => {
  try {
    const result = await Api.patch('leaving-messages', id, data, params)
    dispatch(createToast({ type: 'success', message: 'Leaving message updated successfully' }))
    return result
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: (e as Error).message }))
    throw e
  }
})

export const leavingMessagesSlice = createSlice({
  name: 'leavingMessages',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<LeavingMessage | LeavingMessage[]>) => {
      state.data = mergeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending('loading'))
      .addCase(findAsync.fulfilled, handleFulfilledOverwriteState)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, handlePending('patching'))
      .addCase(patchAsync.fulfilled, handleFulfilledMergeState)
      .addCase(patchAsync.rejected, handleRejected)
  }
})

export const { dataAdded } = leavingMessagesSlice.actions

export default leavingMessagesSlice.reducer
