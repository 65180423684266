import * as utils from 'denisonweb-utils'
import { singular } from 'pluralize'

export * from 'denisonweb-utils'

export const camelToDashCase = (str: string): string => {
  return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase()
}

export const convertServiceToModelName = (serviceName: string): string => {
  return singular(utils.capitalizeFirstLetter(utils.kebabCaseToCamelCase(serviceName)))
}

export const dashToCamelCase = (str: string): string => {
  return str.replace(/-./g, (match) => match.charAt(1).toUpperCase())
}

export const hasOverlap = (array1: string[], array2: string[]): boolean => {
  const set1 = new Set(array1)
  for (const element of array2) {
    if (set1.has(element)) {
      return true
    }
  }
  return false
}

export const setFieldToNull = <T extends object>(array: T[], field: keyof T): T[] => array.map((obj) => ({ ...obj, [field]: null }))
