import type { Action, PayloadAction } from '@reduxjs/toolkit'

import type { DomainData } from '../../lib/api'
import { mergeData, removeData } from '../../lib/util'

export interface DataState<T extends DomainData> {
  data: T[]
  creating: boolean
  loading: boolean
  patching: boolean
  removing: boolean

  errorMessage?: string
}

// eslint-disable-next-line
const resetLoadingState = <T extends Pick<DataState<any>, 'creating' | 'loading' | 'patching' | 'removing'>>(state: T) => {
  state.creating = false
  state.loading = false
  state.patching = false
  state.removing = false
}

export const handleFulfilledMergeState = <T extends DomainData, D extends DataState<T>>(state: D, action: PayloadAction<T | T[]>) => {
  if (action.payload) {
    state.data = mergeData(state.data, action.payload)
  }

  resetLoadingState(state)
}

export const handleFulfilledOverwriteState = <T extends DomainData, D extends DataState<T>>(state: D, action: PayloadAction<T[]>) => {
  if (action.payload) {
    state.data = action.payload
  }

  resetLoadingState(state)
}

export const handleRejected = <T extends DomainData, D extends DataState<T>>(state: D, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message

  resetLoadingState(state)
}

export const handlePending =
  (loadingStateProperty: keyof Pick<DataState<DomainData>, 'loading' | 'creating' | 'patching' | 'removing'>, resetData = false) =>
  <T extends DomainData>(state: DataState<T>) => {
    delete state.errorMessage

    state[loadingStateProperty] = true

    if (resetData) {
      state.data = []
    }
  }

export const handleRemoveFulfilled = <T extends DomainData, D extends DataState<T>>(state: D, action: PayloadAction<T>) => {
  if (action.payload) {
    state.data = removeData(state.data, action.payload)
  }

  resetLoadingState(state)
}
