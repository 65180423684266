import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { TwitchIntegration, TwitchIntegrationData, TwitchIntegrationPatch } from '../../../models/TwitchIntegration'

import { Api } from '../../../lib/api'

import { createToast } from '../toasts/toastsSlice'

import { type DataState, handleFulfilledMergeState, handleFulfilledOverwriteState, handlePending, handleRejected } from '../helpers'

const initialState: DataState<TwitchIntegration> = {
  creating: false,
  data: [],
  loading: false,
  patching: false,
  removing: false
}

interface ICreateParams {
  id: string
  data: TwitchIntegrationData

  params?: Params
}

interface IPatchParams {
  id: string
  data: TwitchIntegrationPatch

  params?: Params
}

export const findAsync = createAsyncThunk('twitch-integrations/find', async (params: Params | undefined, { dispatch }): Promise<TwitchIntegration[]> => {
  try {
    const { data } = await Api.find('twitch-integrations', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting twitch-integrations: ${(e as Error).message}` }))
    throw e
  }
})

export const createAsync = createAsyncThunk('twitch-integrations/create', async ({ data, params }: ICreateParams, { dispatch }): Promise<TwitchIntegration> => {
  try {
    return await Api.create('twitch-integrations', data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting twitch-integrations: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk(
  'twitch-integrations/patch',
  async ({ id, data, params }: IPatchParams, { dispatch }): Promise<TwitchIntegration> => {
    try {
      return await Api.patch('twitch-integrations', id, data, params)
    } catch (e: unknown) {
      dispatch(createToast({ type: 'error', message: `Error updating twitch-integration: ${(e as Error).message}` }))
      throw e
    }
  }
)

export const twitchIntegrationsSlice = createSlice({
  name: 'twitch-integrations',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<TwitchIntegration>) => {
      state.data = Object.assign(state.data || {}, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending('loading', true))
      .addCase(findAsync.fulfilled, handleFulfilledOverwriteState)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, handlePending('patching'))
      .addCase(patchAsync.fulfilled, handleFulfilledMergeState)
      .addCase(patchAsync.rejected, handleRejected)
  }
})

export const { dataAdded } = twitchIntegrationsSlice.actions

export default twitchIntegrationsSlice.reducer
