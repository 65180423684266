import type { Paginated, Params, ServiceMethods } from '@feathersjs/feathers'
import type { AuthenticationResult } from '@feathersjs/authentication'

import type { Bot, BotData, BotPatch } from '../models/Bot'
import type { DailyQuote, DailyQuoteData, DailyQuotePatch } from '../models/DailyQuote'
import type { DisabledFeature, DisabledFeatureData } from '../models/DisabledFeature'
import type { Guild } from '../models/Guild'
import type { LeavingMessage, LeavingMessageData, LeavingMessagePatch } from '../models/LeavingMessage'
import type { Notification, NotificationData, NotificationPatch } from '../models/Notification'
import type { Permission, PermissionPatch } from '../models/Permission'
import type { Poll, PollData, PollPatch } from '../models/Poll'
import type { PollOption, PollOptionData } from '../models/PollOption'
import type { PollVote, PollVoteData } from '../models/PollVote'
import type { ReactionRole, ReactionRoleData, ReactionRolePatch } from '../models/ReactionRole'
import type { ReactionRoleOption, ReactionRoleOptionData, ReactionRoleOptionPatch } from '../models/ReactionRoleOption'
import type { User, UserData, UserPatch } from '../models/User'
import type { Subscription, SubscriptionData, SubscriptionPatch } from '../models/Subscription'
import type { TwitchIntegration, TwitchIntegrationData, TwitchIntegrationPatch } from '../models/TwitchIntegration'
import type { YoutubeIntegration, YoutubeIntegrationData, YoutubeIntegrationPatch } from '../models/YoutubeIntegration'
import type { TiktokIntegration, TiktokIntegrationData, TiktokIntegrationPatch } from '../models/TiktokIntegration'
import type { WelcomeMessage, WelcomeMessageData, WelcomeMessagePatch } from '../models/WelcomeMessage'

import client from './feathers/feathersClient'
import { url } from './feathers/config'

import { openDiscordLoginOauthUrl, openSocialOauthUrl } from './oauth'

type OAuthProvider = 'twitch' | 'youtube'

type DomainModels = {
  bots: Bot
  'daily-quotes': DailyQuote
  'disabled-features': DisabledFeature
  guilds: Guild
  'leaving-messages': LeavingMessage
  notifications: Notification
  permissions: Permission
  polls: Poll
  'poll-options': PollOption
  'poll-votes': PollVote
  'reaction-roles': ReactionRole
  'reaction-role-options': ReactionRoleOption
  users: User
  subscriptions: Subscription
  'twitch-integrations': TwitchIntegration
  'youtube-integrations': YoutubeIntegration
  'tiktok-integrations': TiktokIntegration
  'welcome-messages': WelcomeMessage
}

export type DomainData = DomainModels[keyof DomainModels]

type DomainModelsData = {
  bots: BotData
  'daily-quotes': DailyQuoteData
  'disabled-features': DisabledFeatureData
  guilds: Guild
  'leaving-messages': LeavingMessageData
  notifications: NotificationData
  permissions: Permission
  polls: PollData
  'poll-options': PollOptionData
  'poll-votes': PollVoteData
  'reaction-roles': ReactionRoleData
  'reaction-role-options': ReactionRoleOptionData
  subscriptions: SubscriptionData
  'twitch-integrations': TwitchIntegrationData
  'youtube-integrations': YoutubeIntegrationData
  'tiktok-integrations': TiktokIntegrationData
  users: UserData
  'welcome-messages': WelcomeMessageData
}

type DomainModelsPatch = {
  bots: BotPatch
  'daily-quotes': DailyQuotePatch
  'disabled-features': DisabledFeature
  guilds: Guild
  notifications: NotificationPatch
  'leaving-messages': LeavingMessagePatch
  permissions: PermissionPatch
  polls: PollPatch
  'poll-options': PollOption
  'poll-votes': PollVote
  'reaction-roles': ReactionRolePatch
  'reaction-role-options': ReactionRoleOptionPatch
  subscriptions: SubscriptionPatch
  'twitch-integrations': TwitchIntegrationPatch
  'youtube-integrations': YoutubeIntegrationPatch
  'tiktok-integrations': TiktokIntegrationPatch
  users: UserPatch
  'welcome-messages': WelcomeMessagePatch
}

export type DomainModelData = BotData | DisabledFeatureData | PollData | PollOptionData | UserData
export type DomainModelPatch = SubscriptionPatch | TwitchIntegrationPatch | YoutubeIntegrationPatch | TiktokIntegrationPatch | UserPatch | WelcomeMessagePatch

type ApiResponse<T> = Paginated<T>

interface DiscordOauthCompleteEvent extends CustomEvent {
  detail: {
    code: string
  }
}

declare global {
  interface WindowEventMap {
    OAuthComplete: DiscordOauthCompleteEvent
  }
}

let messageReceived = false
const handleDiscordOauth = async (): Promise<User> => {
  return await new Promise<User>((resolve, reject) => {
    const oauthWindow = openDiscordLoginOauthUrl()

    const oauthPoll = setInterval(() => {
      if (oauthWindow?.closed && !messageReceived) {
        clearInterval(oauthPoll)
        reject()
      }
    }, 250)

    window.addEventListener(
      'message',
      async (event) => {
        clearInterval(oauthPoll)
        messageReceived = true
        const { code, status } = event.data
        if (event.origin === window.location.origin && status === 'success' && code) {
          try {
            const { user, refreshToken }: AuthenticationResult = await client.authenticate({
              strategy: 'discord',
              code
            })

            window.localStorage.setItem('feathers-rt', refreshToken)

            resolve(user)
          } catch (e: unknown) {
            console.error(`handleDiscordOauth error: ${(e as Error).stack}`)
            reject(e)
          } finally {
            messageReceived = false
          }
        }
      },
      false
    )
  })
}

let oauthMessageReceived = false
const handleOauthFlow = async (guildId: string, provider: OAuthProvider): Promise<void> => {
  return await new Promise<void>((resolve, reject) => {
    const oauthWindow = openSocialOauthUrl(provider)

    const oauthPoll = setInterval(() => {
      if (oauthWindow?.closed && !oauthMessageReceived) {
        clearInterval(oauthPoll)
        reject()
      }
    }, 250)

    window.addEventListener(
      'message',
      async (event) => {
        clearInterval(oauthPoll)
        oauthMessageReceived = true
        const { code, status } = event.data
        if (event.origin === window.location.origin && status === 'success' && code) {
          try {
            // eslint-disable-next-line
            const result = await (client.service(`${provider}-integrations`) as any).create({
              code,
              guildId
            })
            resolve(result)
          } catch (e: unknown) {
            console.error(`handleOauthFlow error: ${(e as Error).stack}`)
            reject(e)
          } finally {
            oauthMessageReceived = false
          }
        }
      },
      false
    )
  })
}

const withRetryOnAuth = async <S extends keyof DomainModels, T>(service: S, operation: keyof ServiceMethods<DomainModels[S]>, args: unknown[]): Promise<T> => {
  try {
    return await (client.service<S>(service) as any)[operation](...args) // eslint-disable-line
  } catch (e: unknown) {
    if ((e as Error).message.includes('Cannot redefine property: Symbol(@feathersjs/schema/dispatch)')) {
      return withRetryOnAuth(service, operation, args)
    }

    if ((e as Error)?.message.includes('jwt expired')) {
      return new Promise<T>((resolve) => {
        const handleAuthenticated = async () => {
          client.off('authenticated', handleAuthenticated)
          const result = await (client.service<S>(service) as any)[operation](...args) // eslint-disable-line
          resolve(result)
        }
        client.on('authenticated', handleAuthenticated)
      })
    }

    throw e
  }
}

export class Api {
  static async logout(): Promise<void> {
    // eslint-disable-next-line
    ;(client.service('refresh-tokens') as any).remove(null).catch((e: any) => void e)
    window.localStorage.removeItem('feathers-rt')
    client.logout()
  }

  static async login(): Promise<User> {
    try {
      const accessToken = window.localStorage.getItem('feathers-jwt')
      const refreshToken = window.localStorage.getItem('feathers-rt')

      if (refreshToken) {
        const fetchResponse = await fetch(`${url}refresh-tokens`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ accessToken, refreshToken })
        })
        const result = await fetchResponse.json()

        if (result.message === 'refreshToken is required') {
          window.localStorage.removeItem('feathers-rt')
        }

        if (result.message === 'accessToken and refreshToken is required') {
          window.localStorage.removeItem('feathers-jwt')
          window.localStorage.removeItem('feathers-rt')
        }

        if (result.accessToken) {
          window.localStorage.setItem('feathers-jwt', result.accessToken)
        }

        if (result.refreshToken) {
          window.localStorage.setItem('feathers-rt', result.refreshToken)
        }

        await client.reAuthenticate()

        return result.user
      } else if (accessToken) {
        const response: AuthenticationResult | void = await client.reAuthenticate()

        if (response.refreshToken) {
          window.localStorage.setItem('feathers-rt', response.refreshToken)
        }

        return response?.user
      } else {
        return await handleDiscordOauth()
      }
    } catch (e: unknown) {
      if (e) {
        console.error(`Authentication error ${(e as Error).stack}`)
      }
      throw e
    }
  }

  static async handleOAuthIntegration(guildId: string, provider: OAuthProvider): Promise<void> {
    return handleOauthFlow(guildId, provider)
  }

  static async get<S extends keyof DomainModels>(service: S, id: string, params?: Params): Promise<DomainModels[S]> {
    return withRetryOnAuth<S, DomainModels[S]>(service, 'get', [id, params])
  }

  static async find<S extends keyof DomainModels>(service: S, params?: Params): Promise<ApiResponse<DomainModels[S]>> {
    return withRetryOnAuth<S, ApiResponse<DomainModels[S]>>(service, 'find', [params])
  }

  static async create<S extends keyof Omit<DomainModels, 'guilds' | 'stats' | 'permissions'>>(
    service: S,
    data: DomainModelsData[S],
    params?: Params
  ): Promise<DomainModels[S]> {
    return withRetryOnAuth<S, DomainModels[S]>(service, 'create', [data, params])
  }

  static async patch<S extends keyof Omit<DomainModels, 'guilds' | 'stats' | 'poll-options' | 'poll-votes'>>(
    service: S,
    id: string,
    data: DomainModelsPatch[S],
    params?: Params
  ): Promise<DomainModels[S]> {
    return withRetryOnAuth<S, DomainModels[S]>(service, 'patch', [id, data, params])
  }

  static async update<S extends keyof Omit<DomainModels, 'guilds' | 'stats' | 'poll-options' | 'poll-votes'>, D extends DomainModelsPatch[S]>(
    service: S,
    id: string,
    data: D,
    params?: Params
  ): Promise<DomainModels[S]> {
    return withRetryOnAuth<S, DomainModels[S]>(service, 'update', [id, data, params])
  }

  static async remove<S extends keyof Omit<DomainModels, 'guilds' | 'stats' | 'permissions'>>(
    service: S,
    id: string,
    params?: Params
  ): Promise<DomainModels[S]> {
    return withRetryOnAuth<S, DomainModels[S]>(service, 'remove', [id, params])
  }
}
